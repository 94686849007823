import { apiClient } from "./http";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { useRouteLoaderData } from "react-router-dom";
import { MyCompanyType } from "@/services/companies.ts";

export const GeneralSettingsSchema = z.object({
  businessDays: z.string().array(),
  firstMonthPercentage: z.number().nullable(),
  monthDivision: z.record(z.string(), z.number().nullable()),
  fillMissingKmsInMovements: z.boolean(),
  considerRoundTripsInMovements: z.boolean(),
  allowEditingOfUnprocessedExpenses: z.boolean(),
  minExpenseVariation: z.number(),
  maxExpenseVariation: z.number(),
});

export type GeneralSettingsType = z.infer<typeof GeneralSettingsSchema>;

export const getGeneralSettings = async (signal: AbortSignal) => {
  const response = await apiClient.get("/settings/general", { signal });
  return GeneralSettingsSchema.parse(response.data);
};

export const GeneralSettingsFormSchema = z
  .object({
    businessDays: z.string().array(),
    firstMonthPercentage: z.preprocess(
      (val) => (val && typeof val === "string" ? Number(val) : val),
      z.number(),
    ),
    monthDivision: z.preprocess(
      (val) =>
        val && typeof val === "object"
          ? Object.entries(val)
              .map(([key, v]): [string, number | undefined] => [
                key,
                v && typeof v === "string"
                  ? Number(v)
                  : typeof v === "number"
                    ? v
                    : undefined,
              ])
              .filter(([, v]) => typeof v !== "undefined")
              .reduce(
                (prev, [k, v]) => ({
                  ...prev,
                  [k]: v,
                }),
                {},
              )
          : val,
      z.record(z.string(), z.number()),
    ),
    fillMissingKmsInMovements: z.boolean(),
    considerRoundTripsInMovements: z.boolean(),
    allowEditingOfUnprocessedExpenses: z.boolean(),
    expenseVariation: z.tuple([z.number(), z.number()]),
  })
  .transform(({ expenseVariation, ...rest }) => ({
    ...rest,
    minExpenseVariation: expenseVariation[0],
    maxExpenseVariation: expenseVariation[1],
  }));

export type GeneralSettingsPreForm = {
  businessDays: string[];
  firstMonthPercentage: number;
  monthDivision: Record<string, number | null>;
  fillMissingKmsInMovements: boolean;
  considerRoundTripsInMovements: boolean;
  allowEditingOfUnprocessedExpenses: boolean;
  expenseVariation: [number, number];
};

export type GeneralSettingsFormType = z.infer<typeof GeneralSettingsFormSchema>;

const updateGeneralSettings = async (data: GeneralSettingsType) => {
  const response = await apiClient.put("/settings/general", data);
  return response.data;
};

export const useUpdateGeneralSettings = () =>
  useMutation({
    mutationKey: ["general_settings"],
    mutationFn: updateGeneralSettings,
  });

export const IntegrationSettingsSchema = z.object({
  googleMapsApiKey: z.string().min(30).nullable(),
  komiDocConfig: z.object({
    token: z.string().min(30).nullable(),
    host: z.string().url(),
    idParentFolder: z.string(),
    categoryFilingId: z.string(),
  }),
});

export type IntegrationSettingsType = z.infer<typeof IntegrationSettingsSchema>;

export const getIntegrationSettings = async (signal: AbortSignal) => {
  const response = await apiClient.get("/settings/integration", { signal });
  return IntegrationSettingsSchema.parse(response.data);
};

const updateIntegrationSettings = async (data: IntegrationSettingsType) => {
  const response = await apiClient.put("/settings/integration", data);
  return response.data;
};

export const useUpdateIntegrationSettings = () =>
  useMutation({
    mutationKey: ["general_settings"],
    mutationFn: updateIntegrationSettings,
  });

const GlobalSettingsSchema = z.object({
  allowEditingOfUnprocessedExpenses: z.boolean(),
});

export type GlobalSettingsType = z.infer<typeof GlobalSettingsSchema>;

export const getGlobalSettings = async (signal: AbortSignal) => {
  const response = await apiClient.get("/settings/global", { signal });
  return GlobalSettingsSchema.parse(response.data);
};

export const useGlobalSettings = () => {
  const loadedData = useRouteLoaderData("main") as LoadedData;
  return loadedData?.settings;
};

export type LoadedData = {
  settings: GlobalSettingsType;
  companies: MyCompanyType[];
};
