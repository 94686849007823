import qs from "qs";
import { AxiosRequestConfig } from "axios";
import { ParamsType } from "@/services/common.ts";

export const generateFilterableRequest = <T>({
  paginationModel,
  sortModel,
}: Omit<ParamsType, "filterModel">): AxiosRequestConfig<T> => {
  return {
    params: {
      page: paginationModel.page,
      size: paginationModel.pageSize,
      sort: sortModel?.map(
        (sort) => `${sort.field.replace(".id", ".name")},${sort.sort}`,
      ),
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  };
};
