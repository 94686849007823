import { apiClient } from "@/services/http.ts";
import { useMutation, useQuery } from "@tanstack/react-query";
import { generateFilterableRequest } from "@/libs/generate-filterable-request.ts";
import { z } from "zod";
import { pageSchema, ParamsType } from "@/services/common.ts";

const HolidaySchema = z.object({
  id: z.string().uuid(),
  date: z.preprocess(
    (val) => (val && typeof val === "string" ? new Date(val) : undefined),
    z.date(),
  ),
  description: z.string(),
});

export type HolidayType = z.infer<typeof HolidaySchema>;

const getHolidays = async (
  { paginationModel, filterModel, sortModel }: ParamsType,
  signal: AbortSignal,
) => {
  const response = await apiClient.post("/holidays/search", filterModel, {
    ...generateFilterableRequest({
      paginationModel,
      sortModel,
    }),
    signal,
  });
  return pageSchema(HolidaySchema).parse(response.data);
};

export const useHolidays = (params: ParamsType) =>
  useQuery({
    queryKey: ["holidays", params],
    queryFn: ({ signal }) => getHolidays(params, signal),
  });

export const getHoliday = async (id: string, signal: AbortSignal) => {
  const response = await apiClient.get(`/holidays/${id}`, { signal });
  return HolidaySchema.parse(response.data);
};

export const MutateHolidaySchema = z.object({
  date: z.date(),
  description: z.string().min(1),
});

export type MutateHolidayType = z.infer<typeof MutateHolidaySchema>;

const createHoliday = async (data: MutateHolidayType) => {
  const response = await apiClient.post("/holidays", data);
  return response.data;
};

export const useCreateHoliday = () =>
  useMutation({
    mutationKey: ["holidays"],
    mutationFn: createHoliday,
  });

const updateHoliday = async (id: string, data: MutateHolidayType) => {
  const response = await apiClient.put(`/holidays/${id}`, data);
  return response.data;
};

export const useUpdateHoliday = (id: string) =>
  useMutation({
    mutationKey: ["holidays", id],
    mutationFn: (vars: MutateHolidayType) => updateHoliday(id, vars),
  });

const deleteHoliday = async (id: string) => {
  const response = await apiClient.delete(`/holidays/${id}`);
  return response.data;
};

export const useDeleteHoliday = (id: string) =>
  useMutation({
    mutationKey: ["holidays"],
    mutationFn: () => deleteHoliday(id),
  });
