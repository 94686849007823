import { apiClient, zodList } from "@/services/http.ts";
import { atom, useRecoilState } from "recoil";
import { z } from "zod";
import { localStorageEffect } from "@/services/local-storage.effect.ts";
import { useMutation, useQuery } from "@tanstack/react-query";
import qs from "qs";
import { AddressSchema, pageSchema, ParamsType } from "@/services/common.ts";
import { useRouteLoaderData } from "react-router-dom";
import { LoadedData } from "@/services/settings.ts";

export const GEN_CURRENT_COMPANY = "gen_current_company";

const CURRENT_COMPANY_ATOM = atom<MyCompanyType | null>({
  key: "gen/current-company",
  default: null,
  effects: [localStorageEffect(GEN_CURRENT_COMPANY)],
});

export const useCurrentCompany = () => useRecoilState(CURRENT_COMPANY_ATOM);

const MyCompanySchema = z.object({
  id: z.string(),
  name: z.string(),
  active: z.boolean(),
  base64Logo: z.string().nullable(),
});

export type MyCompanyType = z.infer<typeof MyCompanySchema>;

export const getMyCompanies = async (signal: AbortSignal) => {
  const response = await apiClient.get<MyCompanyType[]>("/tenants/mine", {
    signal,
  });
  return zodList(MyCompanySchema).parse(response.data);
};

const CompanyResponseSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  active: z.boolean(),
});

const getCompanies = async (
  { paginationModel, sortModel, filterModel }: ParamsType,
  signal: AbortSignal,
) => {
  const response = await apiClient.post("/tenants/search", filterModel, {
    params: {
      page: paginationModel.page,
      size: paginationModel.pageSize,
      sort: sortModel?.map(
        (sort) => `${sort.field.replace(".id", ".name")},${sort.sort}`,
      ),
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    signal,
  });
  return pageSchema(CompanyResponseSchema).parse(response.data);
};

export const useCompanies = (params: ParamsType) => {
  return useQuery({
    queryKey: ["companies", params],
    queryFn: ({ signal }) => getCompanies(params, signal),
  });
};

export const CompanySchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1),
  kmValue: z.preprocess(
    (val) => (typeof val !== "undefined" ? Number(val) : undefined),
    z.number().min(0.01),
  ),
  active: z.boolean(),
  offices: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
      address: AddressSchema,
      active: z.boolean(),
    }),
  ),
});

export type CompanyType = z.infer<typeof CompanySchema>;

export const getCompany = async (id: string, signal: AbortSignal) => {
  const response = await apiClient.get(`/tenants/${id}`, { signal });
  return CompanySchema.parse(response.data);
};

export const ModifyCompanyRequestSchema = CompanySchema.omit({
  id: true,
  offices: true,
  active: true,
}).merge(
  z.object({
    active: z.boolean().optional(),
    offices: z
      .array(
        z.object({
          id: z.string().optional(),
          name: z.string().min(1),
          address: AddressSchema,
          active: z.boolean().optional(),
        }),
      )
      .min(1),
  }),
);

export type ModifyCompanyRequestType = z.infer<
  typeof ModifyCompanyRequestSchema
>;

const createCompany = async (data: ModifyCompanyRequestType) => {
  const response = await apiClient.post<string>("/tenants", data);
  return response.data;
};

export const useCreateCompany = () =>
  useMutation({
    mutationKey: ["companies"],
    mutationFn: createCompany,
  });

const updateCompany = async (
  companyId: string,
  data: ModifyCompanyRequestType,
) => {
  const response = await apiClient.put(`/tenants/${companyId}`, data);
  return response.data;
};

export const useUpdateCompany = (companyId: string) =>
  useMutation({
    mutationKey: ["companies", { companyId }],
    mutationFn: (variables: ModifyCompanyRequestType) =>
      updateCompany(companyId, variables),
  });

export const useLoadedCompany = () => {
  const loadedData = useRouteLoaderData("main") as LoadedData;
  return (loadedData?.companies ?? []) as MyCompanyType[];
};
