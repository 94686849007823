import { getCompany, getMyCompanies } from "@/services/companies.ts";
import { LoaderFunctionArgs, Params } from "react-router-dom";
import { getEmployee, getEmployeeKm } from "@/services/employees.ts";
import { getClient } from "@/services/clients.ts";
import {
  getGeneralSettings,
  getGlobalSettings,
  getIntegrationSettings,
} from "@/services/settings.ts";
import { getHoliday } from "@/services/holidays.ts";
import { getFlow } from "@/services/flows.ts";
import { UUIDSchema, UUIDType } from "@/services/common.ts";
import { getUser } from "@/services/users.ts";
import { z } from "zod";

const validateId = <T extends string>(params: Params<T>): UUIDType => {
  try {
    return UUIDSchema.parse(params);
  } catch {
    throw new Response("Not Found", { status: 404 });
  }
};

const employeeKmParams = z.object({
  id: z.string().uuid(),
  kmId: z.string().uuid(),
});

export const Loaders = {
  async loadRequiredData({ request }: LoaderFunctionArgs) {
    const companies = await getMyCompanies(request.signal);
    const settings = await getGlobalSettings(request.signal);
    return { companies, settings };
  },
  async getEmployee({ params, request }: LoaderFunctionArgs) {
    const { id } = validateId(params);
    return await getEmployee(id, request.signal);
  },
  async getClient({ params, request }: LoaderFunctionArgs) {
    const { id } = validateId(params);
    return getClient(id, request.signal);
  },
  async getCompany({ params, request }: LoaderFunctionArgs) {
    const { id } = validateId(params);
    return getCompany(id, request.signal);
  },
  async getGeneralSettings({ request }: LoaderFunctionArgs) {
    return getGeneralSettings(request.signal);
  },
  async getHoliday({ params, request }: LoaderFunctionArgs) {
    const { id } = validateId(params);
    return getHoliday(id, request.signal);
  },
  async getFlow({ params, request }: LoaderFunctionArgs) {
    const { id } = validateId(params);
    return getFlow(id, request.signal);
  },
  async getIntegrationSettings({ request }: LoaderFunctionArgs) {
    return getIntegrationSettings(request.signal);
  },
  async getUser({ params, request }: LoaderFunctionArgs) {
    const { id } = validateId(params);
    return getUser(id, request.signal);
  },
  async getEmployeeKm({ params, request }: LoaderFunctionArgs) {
    const { id, kmId } = employeeKmParams.parse(params);
    return getEmployeeKm(id, kmId, request.signal);
  },
};
