import { z, ZodSchema } from "zod";
import {
  GridFilterModel,
  GridPaginationModel,
  GridSortItem,
} from "@mui/x-data-grid-pro";

const PageSchema = z.object({
  content: z.array(z.any()),
  page: z.object({
    number: z.number(),
    size: z.number(),
    totalElements: z.number(),
    totalPages: z.number(),
  }),
});

export type ParamsType = {
  filterModel: GridFilterModel;
  sortModel: GridSortItem[];
  paginationModel: GridPaginationModel;
};

export const pageSchema = (schema: ZodSchema) => {
  return PageSchema.omit({ content: true }).merge(
    z.object({
      content: z.array(schema),
    }),
  );
};

export const UUIDSchema = z.object({
  id: z.string().uuid(),
});

export type UUIDType = z.infer<typeof UUIDSchema>;

export const AddressSchema = z.object({
  street: z.string().min(1),
  number: z.string().min(1),
  postalCode: z.string().min(1),
  city: z.string().min(1),
});
