import { primeApi } from "@primeit/react-auth";
import { z, ZodSchema } from "zod";
import { GEN_CURRENT_COMPANY } from "@/services/companies.ts";

const api = primeApi({
  clientId: import.meta.env.VITE_SSO_CLIENT_ID,
  ssoUrl: import.meta.env.VITE_SSO_URL,
  apiUrl: import.meta.env.VITE_API_URL,
});

export const apiClient = api.apiClient;

apiClient.interceptors.request.use((config) => {
  const currentCompany = JSON.parse(
    localStorage.getItem(GEN_CURRENT_COMPANY) ?? "null",
  );
  if (currentCompany) {
    config.headers.set("X-Tenant", currentCompany.id);
  }
  return config;
});

export const zodList = (schema: ZodSchema) => {
  return z.array(schema);
};
